<template>
    <div style="padding: 1%;">
		 <el-tabs type="border-card" v-model="active">
		   <el-tab-pane label="我的申请" name="first">
			   <ApplyChild></ApplyChild>
		   </el-tab-pane>
		   <el-tab-pane label="我的承接" name="first1">
			   <AcceptChild></AcceptChild>
		   </el-tab-pane>
		 </el-tabs>
    </div>
</template>
<script>
	import ApplyChild from './children/applyChild.vue';
	import AcceptChild from './children/acceptChild.vue';
    export default {
    	name:'myApply',
		props:[],
        data() {
    		return {
				active:'first',
			}
        },
		components:{
			ApplyChild,
			AcceptChild,
		},
        methods: {
        }
    };
</script>

<style scoped>
	.grey-line .el-row{
		border-bottom: 1px solid #dcdfe6;
	}
	.grey-line .el-row:last-child{
		border-bottom: none;
	}

    .userInfo_list .grid-content {
        line-height: 20px;
    }

    .userInfo_list .lost-title {
        font-weight: 400;
        color: #1f2f3d;
        font-size: 20px;
    }

    .userInfo_list .title-content {
        font-weight: 400;
        color: #1f2f3d;
        font-size: 20px;
        margin-left: 5px;
    }

    .grid-content {
        margin: 10px 0;
        display: flex;
        flex-direction: row;
        align-items: center !important;
    }

    .complaint-title {
        align-items: center;
        margin-right: 5px;
        font-size: 16px;
        display: flex;
		font-weight: bolder;
		color: #1f2f3d;
        white-space: nowrap;
    }

    .title-content {
        display: flex;
        align-items: center;
    }
</style>
