<template>
	<div>
		<div class="container">
			<div class="handle-box">
				<el-select placeholder="申请类型" clearable @change="clickSearch" v-model="query.type" style="width: 150px;" class="handle-input mr10">
					<el-option label="项目" :value="1"></el-option>
					<el-option label="运力" :value="2"></el-option>
					<el-option label="配套" :value="3"></el-option>
				</el-select>
				<el-select placeholder="状态" clearable @change="clickSearch" v-model="query.status" style="width: 150px;" class="handle-input mr10">
					<el-option label="等待处理" :value="0"></el-option>
					<el-option label="申请通过" :value="1"></el-option>
					<el-option label="申请驳回" :value="-1"></el-option>
					<el-option label="二次申请" :value="2"></el-option>
					<el-option label="二次驳回" :value="-2"></el-option>
					<el-option label="用户取消" :value="-3"></el-option>
				</el-select>
				<el-button type="primary" icon="el-icon-search" @click="clickSearch">搜索</el-button>
			</div>
			
			<el-table ref="myTable" :data="tableData" border>
				<el-table-column
						type="selection"
						width="45">
				</el-table-column>
				<el-table-column prop="applyType" label="申请类型">
					<template slot-scope="scope">
						<span v-if="scope.row.applyType==1">项目申请</span>
						<span v-if="scope.row.applyType==2">运力申请</span>
						<span v-if="scope.row.applyType==3">配套申请</span>
					</template>
				</el-table-column>
				<el-table-column prop="keyName" label="标题"></el-table-column>
				<!-- <el-table-column prop="userInfo.unitName" label="申请人公司"></el-table-column> -->
				<!-- <el-table-column prop="userInfo.contactName" label="联系人"></el-table-column> -->
				<!-- <el-table-column prop="userInfo.contactWay" label="联系电话"></el-table-column> -->
				<el-table-column label="状态">
					<template slot-scope="scope">
						<span v-if="scope.row.status==0">等待处理</span>
						<span v-if="scope.row.status==1">申请通过</span>
						<span v-if="scope.row.status==-1">申请驳回</span>
						<span v-if="scope.row.status==2">二次申请</span>
						<span v-if="scope.row.status==-2">二次驳回</span>
						<span v-if="scope.row.status==-3">用户取消</span>
					</template>
				</el-table-column>
				<el-table-column prop="createTime" label="申请时间"></el-table-column>
				<el-table-column label="操作">
					<template slot-scope="scope">
						<el-button type="text" @click="detail(scope.row.id)">查看</el-button>
						<el-button type="text" v-if="scope.row.status==-1" @click="onceAgain(scope.row.id)">再次申请</el-button>
						<el-button type="text" v-if="scope.row.status==-1 ||scope.row.status==-2" @click="cancel(scope.row.id)">取消申请</el-button>
						<el-button type="text" v-if="scope.row.status==-3" @click="deleteApply(scope.row.id)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>

			<div class="pagination">
				<el-pagination background layout="sizes,total, prev, pager, next"
							   :page-sizes="pageSize"
							   @size-change="handleSizeChange"
							   :current-page="query.pageIndex" :page-size="query.pageRows"
				 :total="pageTotal" @current-change="handlePageChange" prev-click="pageChange(1)" next-click="pageChange(2)"></el-pagination>
			</div>
		</div>
		
		<el-dialog title="详情" :visible.sync="dialogVisible" width="80%">
		  <span>
			  <el-collapse :v-model="applyInfo.applyType">
				  
			    <el-collapse-item v-if="applyInfo.applyType==1" title="项目信息" name="1">
			      <ProjectDetail v-if="applyInfo.applyType==1" :project="applyInfo.keyData"></ProjectDetail>
			    </el-collapse-item>
				
			    <el-collapse-item v-if="applyInfo.applyType==2" title="运力信息" name="2">
			      <CapacityDetail v-if="applyInfo.applyType==2" :capacity="applyInfo.keyData"></CapacityDetail>
			    </el-collapse-item>
				
			    <el-collapse-item v-if="applyInfo.applyType==3" title="配套信息" name="3">
			    </el-collapse-item>
			
				<el-collapse-item title="申请信息" name="4">
					<div style="padding: 0 3%">
						<div class="grey-line">
							<el-row>
								<el-col :span="12">
									<div class="grid-content">
										<div class="complaint-title">申请类型:</div>
										<div class="title-content">
											<span v-if="applyInfo.applyType==1">项目申请</span>
											<span v-if="applyInfo.applyType==2">运力申请</span>
											<span v-if="applyInfo.applyType==3">配套申请</span>
										</div>
									</div>
								</el-col>
								<el-col :span="12">
									<div class="grid-content">
										<div class="complaint-title">申请时间:</div>
										<div class="title-content">
											{{applyInfo.createTime}}
										</div>
									</div>
								</el-col>
							</el-row>
							
						  <el-row>
							<el-col :span="12">
								<div class="grid-content">
									<div class="complaint-title">申请状态:</div>
									<div class="title-content">
										<span v-if="applyInfo.status==0">等待处理</span>
										<span v-if="applyInfo.status==1">申请通过</span>
										<span v-if="applyInfo.status==-1">申请驳回</span>
										<span v-if="applyInfo.status==2">二次申请</span>
										<span v-if="applyInfo.status==-2">二次驳回</span>
										<span v-if="applyInfo.status==-3">用户取消</span>
									</div>
								</div>
							</el-col>
							<el-col :span="12">
								<div class="grid-content">
									<div class="complaint-title">状态:</div>
									<div class="title-content">
										<span v-if="applyInfo.isValid">有效</span>
										<span v-if="!applyInfo.isValid">无效</span>
									</div>
								</div>
							</el-col>
						  </el-row>
						  
						  <el-row> 
							<el-col :span="12">
								<div class="grid-content">
									<div class="complaint-title">驳回原因:</div>
									<div class="title-content">{{applyInfo.rejectReason}}</div>
								</div>
							</el-col>
							<el-col :span="12">
								<div class="grid-content">
									<div class="complaint-title">取消原因:</div>
									<div class="title-content">{{applyInfo.cancelReason}}</div>
								</div>
							</el-col>
						  </el-row>
						 </div>
					</div>
				</el-collapse-item>
			  </el-collapse>
		  </span>
		  <span slot="footer" class="dialog-footer">
		    <el-button @click="dialogVisible = false">关 闭</el-button>
		  </span>
		</el-dialog>
		
	</div>
</template>

<script>
	import ProjectDetail from './projectDetail.vue';
	import CapacityDetail from './capacityDetail.vue';
	export default {
		name:"applyChild",
		data: function() {
			return {
				query: {
					type:'',
					status:'',
					pageIndex: 1,
					pageRows: 10,
				},
				pageSize:[
				    10,20,30,40,50
				],
				tableData: [],
				pageTotal: 0,
				dialogVisible:false,
				applyInfo:{},
			}
		},
		components:{
			ProjectDetail,
			CapacityDetail,
		},
		created() {
			this.handleSearch();
		},
		methods: {
			clickSearch: function() {
				this.query.pageIndex = 1;
				this.handleSearch();
			},
            handleSizeChange:function (val) {
				this.query.pageRows = val
                this.handleSearch()
            },
			handleSearch: function() {
				//查询
				this.HTTP.post('/apply/list', this.query).then(res => {
					if (res.data.code == 1) {
						this.tableData = res.data.rows
						this.pageTotal = res.data.counts
					}else{
						this.$message.error(res.data.message);
					}
				}).catch(err => {
					this.$message.error(err);
				})
			},
			handlePageChange: function(e) {
				this.query.pageIndex = e
				this.handleSearch()
			},
			pageChange: function(type) {
				if (type == 1) {
					this.query.pageIndex -= 1;
				} else {
					this.query.pageIndex += 1;
				}
				this.handleSearch()
			},
			detail:function(id){
				this.HTTP.get('/apply/detail?id='+id).then(res=>{
					if(res.data.code ==1){
						this.dialogVisible = true;
						this.applyInfo = res.data.data;
					}else{
						this.$message.error(res.data.message);
					}
				}).catch(err=>{
					this.$message.error(err);
				})
			},
			deleteApply:function(id){
				this.$confirm('此操作将删除该记录, 是否继续?', '提示', {
					  confirmButtonText: '确定',
					  cancelButtonText: '取消',
					  type: 'warning'
				}).then(() => {
					this.HTTP.get('/apply/delete?id='+id).then(res=>{
						if(res.data.code==1){
							this.$message.success('操作成功');
							this.clickSearch();
						}else{
							this.$message.error(res.data.message);
						}
					}).catch(err=>{
						this.$message.error(err);
					})
				}).catch(() => {
				});
			},
			onceAgain:function(id){
				this.$confirm('再次申请前请查看驳回原因并修正, 是否继续?', '提示', {
					  confirmButtonText: '确定',
					  cancelButtonText: '取消',
					  type: 'warning'
				}).then(() => {
					this.HTTP.get('/apply/onceAgain?id='+id).then(res=>{
						if(res.data.code==1){
							this.$message.success('操作成功');
							this.clickSearch();
						}else{
							this.$message.error(res.data.message);
						}
					}).catch(err=>{
						this.$message.error(err);
					})
				}).catch(() => {
				});
			},
			cancel:function(id){
				this.$prompt('请输入取消原因', '提示', {
				  confirmButtonText: '确定',
				  cancelButtonText: '取消',
				  inputPattern: /[\u4e00-\u9fa5]/,
				  inputErrorMessage: '请输入中文'
				}).then(({ value }) => {
					this.HTTP.get('/apply/cancel?id='+id+'&reason='+value).then(res=>{
						if(res.data.code==1){
							this.$message.success('操作成功');
							this.clickSearch();
						}else{
							this.$message.error(res.data.message);
						}
					}).catch(err=>{
						this.$message.error(err);
					})
				})
			}
		}
	}
</script>

<style scoped>
	.handle-box {
		margin-bottom: 20px;
	}

	.handle-select {
		width: 120px;
	}

	.handle-input {
		width: 300px;
		display: inline-block;
	}

	.table {
		width: 100%;
		font-size: 14px;
	}

	.red {
		color: #ff0000;
	}

	.mr10 {
		margin-right: 10px;
	}

	.table-td-thumb {
		display: block;
		margin: auto;
		width: 40px;
		height: 40px;
	}
	
	.info-div {
		display: flex;
		justify-content: space-around;
		margin-top: 5px;
		font-size: 13px;
	}
	.info-span1 {
		width: 230px;
		font-size: 15px;
	}
	
	.info-span2 {
		width: 230px;
		font-size: 15px;
	}
	
	.info-title {
		margin-right: 4px;
		color: #000000;
	}
	.form-input{
		width: 215px;
	}
	
	.grey-line .el-row{
		border-bottom: 1px solid #dcdfe6;
	}
	.grey-line .el-row:last-child{
		border-bottom: none;
	}
	.project_list .grid-content {
	    line-height: 20px;
	}
	
	.project_list .lost-title {
	    font-weight: 400;
	    color: #1f2f3d;
	    font-size: 20px;
	}
	
	.project_list .title-content {
	    font-weight: 400;
	    color: #1f2f3d;
	    font-size: 20px;
	    margin-left: 5px;
	}
	
	.grid-content {
	    margin: 10px 0;
	    display: flex;
	    flex-direction: row;
	    align-items: center !important;
	}
	
	.complaint-title {
	    align-items: center;
	    margin-right: 5px;
	    font-size: 16px;
	    display: flex;
		font-weight: bolder;
		color: #1f2f3d;
	    white-space: nowrap;
	}
	
	.title-content {
	    display: flex;
	    align-items: center;
	}
	span >>> .el-collapse-item__content{
		padding-bottom: 10px;
	}
</style>
