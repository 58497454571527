<template>
    <div   style="padding: 0 3%">
        <el-row class="capacity_list">
			<div class="grid-content">
				<div class="lost-title">{{capacity.title}}</div>
			</div>
        </el-row>
		<div class="grey-line">
			<el-row>
				<el-col :span="12">
					<div class="grid-content">
						<div class="complaint-title">服务地区:</div>
						<div class="title-content">{{capacity.regionName}}</div>
					</div>
				</el-col>
				<el-col :span="12">
					<div class="grid-content">
						<div class="complaint-title">车辆数量:</div>
						<div class="title-content">{{capacity.vehicleCount}}</div>
					</div>
				</el-col>
			</el-row>
			
			<el-row>
				<el-col :span="12">
					<div class="grid-content">
						<div class="complaint-title">价格:</div>
						<div class="title-content">{{capacity.price}}</div>
					</div>
				</el-col>
				<el-col :span="12">
					<div class="grid-content">
						<div class="complaint-title">运力标签:</div>
						<div class="title-content">{{capacity.vehicleName}}</div>
					</div>
				</el-col>
			</el-row>
			
			<el-row>
				<el-col :span="12">
					<div class="grid-content">
						<div class="complaint-title">服务时间:</div>
						<div class="title-content">{{capacity.serviceTime}}</div>
					</div>
				</el-col>
			</el-row>
			
			<el-row>
				<el-col :span="12">
					<div class="grid-content">
						<div class="complaint-title">状态:</div>
						<div class="title-content">
							<span v-if="capacity.isValid">有效</span>
							<span v-if="!capacity.isValid">无效</span>
						</div>
					</div>
				</el-col>
			</el-row>
			
			<el-row>
				<el-col :span="12">
					<div class="grid-content">
						<div class="complaint-title">发布公司:</div>
						<div class="title-content">{{capacity.unitName}}</div>
					</div>
				</el-col>
				<el-col :span="12">
					<div class="grid-content">
						<div class="complaint-title">公司联系人:</div>
						<div class="title-content">{{capacity.contactName}}</div>
					</div>
				</el-col>
			</el-row>
			
			<div style="width: 100%;border: 1px solid #d8d8d8;height: 300px;margin-top: 10px;">
				<div style="margin-top: 2%;margin-left: 3%;">详细描述</div>
				<el-input type="textarea" v-model="capacity.detailDesc" :rows="12" resize="none" readonly="readonly" style="margin-top: 1%;margin-left: 1%;width: 98%;"></el-input>
			</div>
		</div>
    </div>
</template>
<script>
    export default {
    	name:'capacityDetail',
        props: ['capacity'],
        data() {
    		return {

			}
        },
        created() {
        },
        methods: {
        }
    };
</script>

<style scoped>
	.grey-line .el-row{
		border-bottom: 1px solid #dcdfe6;
	}
	.grey-line .el-row:last-child{
		border-bottom: none;
	}

    .capacity_list .grid-content {
        line-height: 20px;
    }

    .capacity_list .lost-title {
        font-weight: 400;
        color: #1f2f3d;
        font-size: 20px;
    }

    .capacity_list .title-content {
        font-weight: 400;
        color: #1f2f3d;
        font-size: 20px;
        margin-left: 5px;
    }

    .grid-content {
        margin: 10px 0;
        display: flex;
        flex-direction: row;
        align-items: center !important;
    }

    .complaint-title {
        align-items: center;
        margin-right: 5px;
        font-size: 16px;
        display: flex;
		font-weight: bolder;
		color: #1f2f3d;
        white-space: nowrap;
    }

    .title-content {
        display: flex;
        align-items: center;
    }
</style>
