<template>
	<div>
		<div class="container">
			<div class="handle-box">
				<el-select placeholder="承接类型" clearable @change="clickSearch" v-model="query.type" style="width: 150px;" class="handle-input mr10">
					<el-option label="项目" :value="1"></el-option>
					<el-option label="运力" :value="2"></el-option>
					<el-option label="配套" :value="3"></el-option>
				</el-select>
				<el-select placeholder="状态" clearable @change="clickSearch" v-model="query.status" style="width: 150px;" class="handle-input mr10">
					<el-option label="合作暂停" :value="0"></el-option>
					<el-option label="合作中" :value="1"></el-option>
					<el-option label="合作到期" :value="-1"></el-option>
				</el-select>
				<el-select placeholder="合同状态" clearable @change="clickSearch" v-model="query.isUpload" style="width: 150px;" class="handle-input mr10">
					<el-option label="已上传" :value="1"></el-option>
					<el-option label="未上传" :value="0"></el-option>
				</el-select>
				
				<el-button type="primary" icon="el-icon-search" @click="clickSearch">搜索</el-button>
				<el-button type="primary" @click="uploadStatement" :disabled="showBtn">上传流水</el-button>
			</div>
			
			<el-table ref="myTable" :data="tableData" border @select="selectChange">
				<el-table-column
						type="selection"
						width="45">
				</el-table-column>
				<el-table-column prop="acceptType" label="承接类型">
					<template slot-scope="scope">
						<span v-if="scope.row.acceptType==1">项目</span>
						<span v-if="scope.row.acceptType==2">运力</span>
						<span v-if="scope.row.acceptType==3">配套</span>
					</template>
				</el-table-column>
				<el-table-column prop="keyName" label="标题"></el-table-column>
				<!-- <el-table-column prop="userInfo.unitName" label="承接人公司"></el-table-column> -->
				<!-- <el-table-column prop="userInfo.contactName" label="联系人"></el-table-column> -->
				<!-- <el-table-column prop="userInfo.contactWay" label="联系电话"></el-table-column> -->
				<el-table-column label="状态">
					<template slot-scope="scope">
						<span v-if="scope.row.status==0">合作暂停</span>
						<span v-if="scope.row.status==1">合作中</span>
						<span v-if="scope.row.status==-1">合作到期</span>
					</template>
				</el-table-column>
				<el-table-column prop="createTime" label="承接时间"></el-table-column>
				<el-table-column label="操作">
					<template slot-scope="scope">
						<el-button type="text" @click="detail(scope.row.id)">查看</el-button>
						<el-button type="text" v-if="scope.row.isUpload==0" @click="openUpload(scope.row.id)">上传合同</el-button>
					</template>
				</el-table-column>
			</el-table>

			<div class="pagination">
				<el-pagination background layout="sizes,total, prev, pager, next"
							   :page-sizes="pageSize"
							   @size-change="handleSizeChange"
							   :current-page="query.pageIndex" :page-size="query.pageRows"
				 :total="pageTotal" @current-change="handlePageChange" prev-click="pageChange(1)" next-click="pageChange(2)"></el-pagination>
			</div>
		</div>
		
		<el-dialog title="详情" :visible.sync="dialogVisible" width="80%">
		  <span>
			  <el-collapse :v-model="acceptInfo.acceptType">
				  
			    <el-collapse-item v-if="acceptInfo.acceptType==1" title="项目信息" name="1">
			      <ProjectDetail v-if="acceptInfo.acceptType==1" :project="acceptInfo.keyData"></ProjectDetail>
			    </el-collapse-item>
				
			    <el-collapse-item v-if="acceptInfo.acceptType==2" title="运力信息" name="2">
			      <CapacityDetail v-if="acceptInfo.acceptType==2" :capacity="acceptInfo.keyData"></CapacityDetail>
			    </el-collapse-item>
				
			    <el-collapse-item v-if="acceptInfo.acceptType==3" title="配套信息" name="3">
			    </el-collapse-item>
				
				<el-collapse-item title="承接信息" name="4">
					<div style="padding: 0 3%">
						<div class="grey-line">
							<el-row>
								<el-col :span="12">
									<div class="grid-content">
										<div class="complaint-title">承接类型:</div>
										<div class="title-content">
											<span v-if="acceptInfo.acceptType==1">项目</span>
											<span v-if="acceptInfo.acceptType==2">运力</span>
											<span v-if="acceptInfo.acceptType==3">配套</span>
										</div>
									</div>
								</el-col>
								<el-col :span="12">
									<div class="grid-content">
										<div class="complaint-title">承接时间:</div>
										<div class="title-content">
											{{acceptInfo.createTime}}
										</div>
									</div>
								</el-col>
							</el-row>
							
						  <el-row>
							<el-col :span="12">
								<div class="grid-content">
									<div class="complaint-title">承接状态:</div>
									<div class="title-content">
										<span v-if="acceptInfo.status==0">合作暂停</span>
										<span v-if="acceptInfo.status==1">合作中</span>
										<span v-if="acceptInfo.status==-1">合作到期</span>
									</div>
								</div>
							</el-col>
							<el-col :span="12">
								<div class="grid-content">
									<div class="complaint-title">状态:</div>
									<div class="title-content">
										<span v-if="acceptInfo.isValid">有效</span>
										<span v-if="!acceptInfo.isValid">无效</span>
									</div>
								</div>
							</el-col>
						  </el-row>
						 </div>
					</div>
				</el-collapse-item>
			  </el-collapse>
		  </span>
		  <span slot="footer" class="dialog-footer">
		    <el-button @click="dialogVisible = false">关 闭</el-button>
		  </span>
		</el-dialog>
		
		<el-dialog title="上传合同" :visible.sync="uploadDialog" width="55%">
			<el-form label-width="80px" style="margin-top: 5%;">
			<el-form-item label="文件选择">
				<el-upload ref="upload"
				  class="upload-demo"
				  action=""
				  :http-request="handlePreview"
				  :on-remove="handleRemove"
				  :file-list="fileList"
				  :limit="1"
				  :show-file-list="true"
				  :auto-upload="true"
				  list-type="text"
				  accept='.pdf'>
				  <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
				  <div slot="tip" class="el-upload__tip">请扫描合同生成pdf文件上传，只能上传一个文件</div>
				</el-upload>
			</el-form-item>
			</el-form>
			
		  <span slot="footer" class="dialog-footer">
		    <el-button @click="uploadDialog = false">取 消</el-button>
			<el-button type="primary" @click="submitUpload">提 交</el-button>
		  </span>
		</el-dialog>
		
		
		<el-dialog title="上传流水" :visible.sync="statementDialog" width="55%">
			<Upload ref="mySon" v-on:cancel="cancel"></Upload>
		</el-dialog>	
			
		
	</div>
</template>

<script>
	import ProjectDetail from './projectDetail.vue';
	import CapacityDetail from './capacityDetail.vue';
	import Upload from '../upload.vue';
	export default {
		name:"acceptChild",
		data: function() {
			return {
				query: {
					type:'',
					status:'',
					isUpload:'',
					pageIndex: 1,
					pageRows: 10,
				},
				pageSize:[
				    10,20,30,40,50
				],
				tableData: [],
				pageTotal: 0,
				dialogVisible:false,
				acceptInfo:{},
				uploadDialog:false,
				acceptId:'',
				fileList:[],
				contractFile:'',
				showBtn:true,
				statementDialog:false,
			}
		},
		components:{
			ProjectDetail,
			CapacityDetail,
			Upload,
		},
		created() {
			this.handleSearch();
		},
		methods: {
			clickSearch: function() {
				this.query.pageIndex = 1;
				this.handleSearch();
			},
            handleSizeChange:function (val) {
				this.query.pageRows = val
                this.handleSearch()
            },
			handleSearch: function() {
				//查询
				this.HTTP.post('/accept/list', this.query).then(res => {
					if (res.data.code == 1) {
						this.tableData = res.data.rows
						this.pageTotal = res.data.counts
					}else{
						this.$message.error(res.data.message);
					}
				}).catch(err => {
					this.$message.error(err);
				})
			},
			handlePageChange: function(e) {
				this.query.pageIndex = e
				this.handleSearch()
			},
			pageChange: function(type) {
				if (type == 1) {
					this.query.pageIndex -= 1;
				} else {
					this.query.pageIndex += 1;
				}
				this.handleSearch()
			},
			detail:function(id){
				this.HTTP.get('/accept/detail?id='+id).then(res=>{
					if(res.data.code ==1){
						this.dialogVisible = true;
						this.acceptInfo = res.data.data;
					}else{
						this.$message.error(res.data.message);
					}
				}).catch(err=>{
					this.$message.error(err);
				})
			},
			openUpload:function(id){
				this.uploadDialog = true;
				this.acceptId = id;
				this.fileList = [];
				this.contractFile = '';
			},
			handlePreview:function(file){
				let formData = new FormData();
				formData.append('file',file.file);
				formData.append('dir',"acceptContract/");
				this.HTTP.upload('/file/upload',formData).then(res=>{
					if(res.data.code==1){
						this.$message({message:'上传成功',type:'success'});
						this.contractFile = res.data.data.url;
					}else{
						this.$message.error(res.data.message);
					}
				})
			},
			handleRemove:function(file){
				this.contractFile = '';
			},
			submitUpload:function(){
				if(this.contractFile && this.acceptId){
					this.HTTP.get('/accept/update?filePath='+this.contractFile+'&id='+this.acceptId).then(res=>{
						if(res.data.code ==1){
							this.$message.success('操作成功');
							this.contractFile = '';
							this.fileList = [];
							this.uploadDialog = false;
							this.clickSearch();
						}else{
							this.$message.error(res.data.message);
						}
					}).catch(err=>{
						this.$message.error(err);
					})
				}else{
					this.$message.error('参数错误')
				}
			},
			uploadStatement:function(){
				let rows = this.$refs.myTable.selection;
				if(rows.length!=1){
					this.$message.error('请选择一条数据进行后续操作');
					return false;
				}
				let id = rows[0].foreignKey;
				let type = rows[0].acceptType;
				
				let req  = {
					foreignKey:id,
					inputType:type
				}
				this.statementDialog = true;
				this.$nextTick(() => {
				        this.$refs.mySon.initReq(req);
				 })
			},
			selectChange:function(e){
				if(e.length>0){
					this.showBtn = false;
				}else if(e.length<=0){
					this.showBtn = true;
				}
			},
			cancel:function(e){
				this.statementDialog = false;
			}
		}
	}
</script>

<style scoped>
	.handle-box {
		margin-bottom: 20px;
	}

	.handle-select {
		width: 120px;
	}

	.handle-input {
		width: 300px;
		display: inline-block;
	}

	.table {
		width: 100%;
		font-size: 14px;
	}

	.red {
		color: #ff0000;
	}

	.mr10 {
		margin-right: 10px;
	}

	.table-td-thumb {
		display: block;
		margin: auto;
		width: 40px;
		height: 40px;
	}
	
	.info-div {
		display: flex;
		justify-content: space-around;
		margin-top: 5px;
		font-size: 13px;
	}
	.info-span1 {
		width: 230px;
		font-size: 15px;
	}
	
	.info-span2 {
		width: 230px;
		font-size: 15px;
	}
	
	.info-title {
		margin-right: 4px;
		color: #000000;
	}
	.form-input{
		width: 215px;
	}
	
	.grey-line .el-row{
		border-bottom: 1px solid #dcdfe6;
	}
	.grey-line .el-row:last-child{
		border-bottom: none;
	}
	.project_list .grid-content {
	    line-height: 20px;
	}
	
	.project_list .lost-title {
	    font-weight: 400;
	    color: #1f2f3d;
	    font-size: 20px;
	}
	
	.project_list .title-content {
	    font-weight: 400;
	    color: #1f2f3d;
	    font-size: 20px;
	    margin-left: 5px;
	}
	
	.grid-content {
	    margin: 10px 0;
	    display: flex;
	    flex-direction: row;
	    align-items: center !important;
	}
	
	.complaint-title {
	    align-items: center;
	    margin-right: 5px;
	    font-size: 16px;
	    display: flex;
		font-weight: bolder;
		color: #1f2f3d;
	    white-space: nowrap;
	}
	
	.title-content {
	    display: flex;
	    align-items: center;
	}
	span >>> .el-collapse-item__content{
		padding-bottom: 10px;
	}
</style>
